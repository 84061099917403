import React, { useEffect, useState } from 'react';
import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import logo from './assets/images/logo.jpg';
import BusinessDevelopment from './assets/images/BusinessDevelopment.webp';
import ContentWriting from './assets/images/ContentWriting.webp';
import GraphicDesign from './assets/images/GraphicDesign.webp';
import ITJobsEducation from './assets/images/ITJobsEducation.webp';
import SoftwareDevelopment from './assets/images/SoftwareDevelopment.webp';
import UiUXDesign from './assets/images/UiUXDesign.webp';

function App() {
  const [menuOpen, setMenuOpen] = useState(false);

  useEffect(() => {
    console.log(`Menu is ${menuOpen ? 'open' : 'closed'}`);
  }, [menuOpen]);

  const handleNavItemClick = () => {
    setMenuOpen(false); // Zatvori meni nakon klika na stavku
  };

  return (
    <div className="App">
      <div className="header-wrapper"> {/* Ova klasa dodaje zelene okvire */}
        <header>
          <nav className="navbar navbar-expand-lg navbar-custom">
            <div className="container-fluid">
              <a className="navbar-brand" href="#hero">
                <img src={logo} alt="BeeCode Logo" className="logo" />
              </a>
              <button
                className="navbar-toggler"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#navbarNav"
                aria-controls="navbarNav"
                aria-expanded={menuOpen ? "true" : "false"}
                aria-label="Toggle navigation"
                onClick={() => setMenuOpen(!menuOpen)}
              >
                <span className="navbar-toggler-icon"></span>
              </button>
              <div className={`collapse navbar-collapse ${menuOpen ? 'show' : ''}`} id="navbarNav">
                <ul className="navbar-nav ms-auto">
                  <li className="nav-item">
                    <a className="nav-link" href="#services" onClick={handleNavItemClick}>Services</a>
                  </li>
                  <li className="nav-item">
                    <a className="nav-link" href="#about" onClick={handleNavItemClick}>About Us</a>
                  </li>
                  <li className="nav-item">
                    <a className="nav-link" href="#contact" onClick={handleNavItemClick}>Contact</a>
                  </li>
                </ul>
              </div>
            </div>
          </nav>
        </header>
      </div>

      <section id="hero" className="hero d-flex justify-content-center align-items-center text-center">
        {/* Pozadinski video */}
        <div className="video-background">
          <iframe
            src="https://www.youtube.com/embed/8Ayk6oEY7Z4?autoplay=1&mute=1&loop=1&playlist=8Ayk6oEY7Z4&controls=0&showinfo=0&autohide=1&vq=hd1080"
            title="YouTube video player"
            frameBorder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
          ></iframe>
        </div>
        <div className="container hero-content">
          <h1 className="display-4">Welcome to BeeCode</h1>
          <p className="lead">Your Partner in IT Solutions</p>
        </div>
      </section>

      <section id="services" className="services py-5">
        <div className="container">
          <h2 className="text-center mb-5">Our Services</h2>
          <div className="row">
            <div className="col-md-6 col-lg-4 mb-4">
              <div className="card h-100">
                <img src={ITJobsEducation} alt="IT Jobs and Education" className="card-img-top" />
                <div className="card-body">
                  <h3 className="card-title">IT Jobs and Education</h3>
                  <p className="card-text">
                    Comprehensive education and job opportunities in IT, covering various programming languages and technologies.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-md-6 col-lg-4 mb-4">
              <div className="card h-100">
                <img src={SoftwareDevelopment} alt="Software Development" className="card-img-top" />
                <div className="card-body">
                  <h3 className="card-title">Software Development</h3>
                  <p className="card-text">
                    Wide range of software development services including web, mobile, and desktop applications.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-md-6 col-lg-4 mb-4">
              <div className="card h-100">
                <img src={BusinessDevelopment} alt="Business Development" className="card-img-top" />
                <div className="card-body">
                  <h3 className="card-title">Business Development / Consulting</h3>
                  <p className="card-text">
                    We write business plans, give business consulting on how to increase revenue with software, and help find funding for projects.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-md-6 col-lg-4 mb-4">
              <div className="card h-100">
                <img src={UiUXDesign} alt="Ui/UX Design" className="card-img-top" />
                <div className="card-body">
                  <h3 className="card-title">Ui/UX Design</h3>
                  <p className="card-text">
                    Creation of designs for clients' ideas and projects, offering designs for mobile, web applications, and virtual reality experiences.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-md-6 col-lg-4 mb-4">
              <div className="card h-100">
                <img src={GraphicDesign} alt="Graphic Design" className="card-img-top" />
                <div className="card-body">
                  <h3 className="card-title">Graphic Design</h3>
                  <p className="card-text">
                    Creation of logos, posters, & other branding and marketing content.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-md-6 col-lg-4 mb-4">
              <div className="card h-100">
                <img src={ContentWriting} alt="Content Writing" className="card-img-top" />
                <div className="card-body">
                  <h3 className="card-title">Content Writing</h3>
                  <p className="card-text">
                    Writing content for websites, including SEO optimization in English, German, and Bosnian.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section id="about" className="about py-5 bg-light">
        <div className="container">
          <h2 className="text-center mb-5">About Us</h2>
          <p className="text-center">
            BeeCode is an IT firm specializing in software development, IT consulting, and design. Our mission is to help businesses grow by leveraging the power of technology.
          </p>
        </div>
      </section>

      <section id="contact" className="contact py-5">
        <div className="container">
          <h2 className="text-center mb-5">Contact Us</h2>
          <p className="text-center">Email: info@beecode.ba</p>
          <p className="text-center">Phone: +387 61 799 104</p>
          <p className="text-center">Address: Ramiza Salčina 17, 71000 Sarajevo, BiH</p>
        </div>
      </section>

      <footer className="bg-dark text-white text-center py-3">
        <p>&copy; 2024 BeeCode. All rights reserved.</p>
      </footer>
    </div>
  );
}

export default App;
